* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$PRIMARY_COLOR: #8048f4;
$TEXT_COLOR: #333;

html {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}
body {
  font-family: "Inter", sans-serif;
  width: 526px;
  background-color: #f7f7f7;
  min-height: 100vh;
}
