@import "../../index.scss";

.ProjectDetails_Container {

  .ProjectContainer {
    position: relative;
    top: 15vh;

    .Project_Details {
      width: 90%;
      margin: auto;
      padding: 2rem 1rem 1rem 1rem;
      background-color: white;
      // margin-top: -2rem;
      z-index: 12;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .Loader {
        text-align: center;
        color: $PRIMARY_COLOR;
      }

      .Item {
        margin-bottom: 1rem;

        h4 {
          color: $TEXT_COLOR;
          font-size: 14px;
          margin-bottom: 0.25rem;
          font-weight: 600;
        }

        h5 {
          display: flex;
          align-items: center;
          color: #9a9a9a;
          font-weight: 500;
          font-size: 12px;

          img {
            margin-right: 0.25rem;
          }
        }

        .Type_Container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .Type {
            background-color: #dfffee;
            color: #004e25;
            font-size: 12px;
            padding: 8px 12px;
            margin-right: 0.5rem;
            // margin-bottom: 0.5rem;
          }
        }

        .Resource_Container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .Type {
            background-color: #fff4df;
            color: #4e1f00;
            font-size: 12px;
            padding: 8px 12px;
            margin-right: 0.5rem;
            // margin-bottom: 0.5rem;
          }
        }

        .CheckList {
          display: flex;
          align-items: center;
          color: #9a9a9a;
          font-weight: 500;
          font-size: 12px;
          margin: 0.80rem 0;

          .Check {
            color: $PRIMARY_COLOR;
          }

          .Uncheck {
            color: #9a9a9a;
          }

          span {
            width: 12px;
            aspect-ratio: 1/1;
            margin-right: 12px;
            font-size: 14px;
            background-color: #f9f9f9;
          }
        }
      }
    }

    .Invoice_Container {
      width: 90%;
      margin: 1.5rem auto;

      h2 {
        text-align: center;
        color: $TEXT_COLOR;
        font-weight: 400;
        font-size: 1rem;

        span {
          margin-right: 0.5rem;
        }
      }
    }

    .Bar {
      width: 90%;
      margin: 2rem auto;
      height: 2px;
      background-color: $PRIMARY_COLOR;
    }

    .Invoice_Container_Pending {
      width: 90%;
      margin: auto;
      padding: 20px 10px;

      .Invoice_Pending {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        flex-direction: column;

        .Left {
          h4 {
            color: $TEXT_COLOR;
            font-size: 14px;
            margin-bottom: 0.25rem;
            font-weight: 600;
          }

          h5 {
            display: flex;
            align-items: center;
            color: #9a9a9a;
            font-weight: 500;
            font-size: 12px;

            img {
              margin-right: 0.25rem;
            }
          }
        }

        .Right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.5rem;

          button {
            width: 48%;
            padding: 8px 12px;
            background-color: transparent;
            outline: none;
            border-radius: 4px;
            font-weight: 600;
            font-size: 14px;

            a {
              text-decoration: none;
              color: $PRIMARY_COLOR;
            }
            &:nth-child(1) {
              border: 2px solid $PRIMARY_COLOR;
              color: $PRIMARY_COLOR;
            }

            &:nth-child(2) {
              border: 2px solid $PRIMARY_COLOR;
              color: white;
              background-color: $PRIMARY_COLOR;
            }
          }
        }
      }
    }

    .Invoice_Container_Paid {
      width: 90%;
      margin: auto;
      margin-bottom: 4rem;

      .Invoice_Paid {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .Left {
          h4 {
            color: $TEXT_COLOR;
            font-size: 1rem;
            margin-bottom: 0.25rem;
            font-weight: 600;
          }

          h5 {
            display: flex;
            align-items: center;
            color: #9a9a9a;
            font-weight: 500;
            font-size: 14px;

            span {
              margin-right: 0.25rem;
              color: green;
            }
          }
        }

        .Right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.5rem;
          
          button {
            padding: 8px 12px;
            outline: none;
            border: none;
            border-radius: 4px;
            font-weight: 600;
            border-radius: 4px;
            background-color: transparent;
            color: $PRIMARY_COLOR;
          }
        }
      }
    }
  }

}