@import "../../index.scss";

.Profile_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .Agency_Container {
    width: 90%;
    padding: 1.5rem 1rem;
    background-color: white;
    position: relative;
    z-index: 0;
    margin: auto;
    border-radius: 4px;
    margin-bottom: 2rem;
    position: relative;
    top: 15vh;
    .NoAgency {
      width: 80%;
      margin: auto;
      padding: 1rem;
      img {
        width: 100%;
      }
      h2 {
        color: #9a9a9a;
        font-size: 1.2rem;
        text-align: center;
        margin-top: 1rem;
      }
      h3 {
        text-align: center;
        font-size: 14px;
        color: #9a9a9a;
        font-weight: 400;
      }
    }
    h4 {
      color: $TEXT_COLOR;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      font-weight: 600;
    }
    button {
      display: flex;
      width: 100%;
      padding: 12px;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.75rem;
      background-color: transparent;
      border: none;
      outline: none;
      p {
        font-size: 14px;
        color: #333333;
      }
      span {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 10px;
        margin-right: 10px;
      }
    }
  }

  .Profile_Container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 100px;
    h4 {
      color: $TEXT_COLOR;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      font-weight: 600;
    }
    label {
      display: flex;
      flex-direction: column;
      color: #888;
      font-size: 14px;
      margin-bottom: 0.75rem;
      width: 100%;
      input,
      select {
        margin-top: 0.25rem;
        padding: 12px;
        min-height: 48px;
        font-size: 14px;
        background-color: #ebebeb;
        border-radius: 4px;
        border: none;
        outline: none;
        color: $TEXT_COLOR;
        width: 100%;
      }
    }
    button {
      width: 100%;
      padding: 12px;
      background-color: $PRIMARY_COLOR;
      outline: none;
      border: 2px solid $PRIMARY_COLOR;
      color: white;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 600;
      margin: 0.75rem 0;
    }
  }

  .Logout_Container {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .LogOut {
      width: 90%;
      padding: 12px;
      background-color: transparent;
      outline: none;
      border: 2px solid #ff000066;
      color: #ff000066;
      border-radius: 4px;
      font-size: 1rem;
      font-weight: 600;
      margin: 1.5rem 0;
    }
  }
}
