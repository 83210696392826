@import "../../index.scss";
.Signup_Container {
  padding: 2rem;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .Form {
    height: 70%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    .Header {
      margin-bottom: 2rem;
      h3 {
        color: $TEXT_COLOR;
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: 0.25rem;
      }
      h4 {
        color: #9a9a9a;
        font-size: 1rem;
        font-weight: 500;
      }
    }
    label {
      display: flex;
      flex-direction: column;
      color: #9a9a9a;
      margin-bottom: 0.75rem;
      input,
      select {
        width: 100%;
        padding: 14px;
        background-color: #ebebeb;
        border: none;
        outline: none;
        margin-top: 0.25rem;
        border-radius: 4px;

        &::placeholder {
          font-size: 14px;
        }
      }
    }
    .Footer {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        width: 100%;
        padding: 12px;
        background-color: $PRIMARY_COLOR;
        color: white;
        border: none;
        outline: none;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 0.75rem;
        border-radius: 4px;
      }
      p {
        text-align: center;
        color: $TEXT_COLOR;
        font-size: 14px;
        a {
          color: $PRIMARY_COLOR;
          text-decoration: none;
          font-size: 14px;
        }
      }
      .logo {
        height: 30px;
        width: auto;
        opacity: 0.3;
        position: fixed;
        bottom: 20px;
      }
    }
  }
}
