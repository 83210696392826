@import "../../index.scss";
.Home_Container {
  .Top {
    min-height: 180px;
    padding: 2rem 2rem;
    background-color: #eeeeee;
    position: relative;
    .Details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .Left {
        h4 {
          color: $TEXT_COLOR;
          font-size: 16px;
          margin-bottom: 0.25rem;
        }
        h5 {
          color: $TEXT_COLOR;
          font-weight: 300;
        }
      }
      .Right {
        button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          outline: none;
          border: none;
        }
      }
    }
    .Bg1 {
      position: absolute;
      top: 2rem;
      left: 0%;
      transform: translate(-70%, -15%);
    }
    .Bg2 {
      position: absolute;
      bottom: 20%;
      right: 0%;
      transform: translate(-10%, 50%);
    }
  }
  .List_Container {
    width: 90%;
    padding: 2rem 1rem;
    background-color: white;
    position: relative;
    z-index: 12;
    margin: auto;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: -2rem;
    margin-bottom: 3rem;
    font-size: 14px;
    .Count {
      position: absolute;
      background-color: $PRIMARY_COLOR;
      padding: 8px 12px;
      position: absolute;
      left: 8px;
      top: 0;
      transform: translateY(-50%);
      border-radius: 4px;
      p {
        color: white;
        font-weight: 500;
      }
    }
    h5 {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .Project_Item {
      background-color: #f9f9f9;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px 12px;
      border: none;
      border-radius: 4px;
      outline: none;
      margin-bottom: 1rem;
      h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .Status {
        display: flex;
        align-items: center;
        h3 {
          color: #9a9a9a;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
          text-align: left;
          img {
            margin-right: 0.25rem;
          }
        }
      }
      .Status {
        display: flex;
        width: 100%;
        h3 {
          font-size: 12px;
        }
      }
    }
    .No_Projects {
      width: 80%;
      margin: auto;
      padding: 1rem;
      img {
        width: 100%;
      }
      h2 {
        color: #9a9a9a;
        font-size: 1.2rem;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
      h3 {
        text-align: center;
        font-size: 14px;
        color: #9a9a9a;
        font-weight: 400;
      }
    }
  }
  .Completed_Projects {
    width: 90%;
    margin: auto;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 12px 12px;
      background-color: #dfffee;
      border-radius: 4px;
      outline: none;
      border: none;
      .Left {
        width: 20%;
        i {
          font-size: 1.2rem;
          color: #42b277;
        }
      }
      .Right {
        text-align: left;
        h4 {
          color: #42b277;
          font-size: 14px;
          margin-bottom: 0.25rem;
        }
        h5 {
          color: #9a9a9a;
        }
      }
    }
  }
}
