@import "../../index.scss";
.Toast_Container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  .Toast_Box {
    width: 80%;
    margin: auto;
    padding: 1rem;
    background-color: white;
    p {
      color: $TEXT_COLOR;
      margin-bottom: 0.5rem;
    }
    button {
      display: block;
      margin-left: auto;
      padding: 6px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      color: black;
      font-weight: 600;
      font-size: 0.95rem;
    }
  }
}
