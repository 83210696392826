@import "../../index.scss";
.Otp_Container {
  height: 100vh;
  .Top {
    height: 35%;
    background-color: $PRIMARY_COLOR;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: white;
      border: none;
      outline: none;
      color: $TEXT_COLOR;
      font-size: 14px;
    }
    h3 {
      color: white;
      font-size: 1.75rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }
    h4 {
      color: white;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
  .Bottom {
    height: 65%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .Input {
      h6 {
        color: $TEXT_COLOR;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 1rem;
      }
      .Form {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          width: 48px;
          height: 48px;
          border-radius: 4px;
          background-color: #ebebeb;
          border: none;
          outline: none;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: $TEXT_COLOR;
        }
      }
      h5 {
        margin-top: 1rem;
        font-weight: 500;
        font-size: 14px;
        margin-left: 0;
        width: fit-content;
        span {
          color: $PRIMARY_COLOR;
          cursor: pointer;
          margin-left: 4px;
        }
      }
    }
    button {
      width: 100%;
      padding: 12px;
      background-color: $PRIMARY_COLOR;
      color: white;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      margin-bottom: 0.75rem;
      border-radius: 4px;
    }
  }
}
