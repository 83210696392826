@import "../../index.scss";

.Header_Container {
  min-height: 20px;
  padding: 2rem 3rem;
  background-color: #eeeeee;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  button {
    position: absolute;
    left: 1rem;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transform: translateY(-25%);
    background-color: transparent;
    border: none;
    outline: none;
    color: $TEXT_COLOR;
    font-size: 1.5rem;
  }
  h3 {
    text-align: center;
    color: $TEXT_COLOR;
    font-weight: 500;
    font-size: 1.25rem;
  }
  .Bg1 {
    position: absolute;
    top: 2rem;
    left: 0%;
    transform: translate(-50%, 75%);
  }
  .Bg2 {
    position: absolute;
    bottom: 20%;
    right: 0%;
    transform: translate(-10%, 50%);
  }
}
