@import "../../index.scss";
.Completed_Container {
  .Image {
    width: 80%;
    margin: auto;
    top: 15vh;
    position: relative;
    z-index: 12;

    img {
      width: 100%;
    }
    h2 {
      color: #9a9a9a;
      font-size: 1.2rem;
      text-align: center;
      margin-top: 1rem;
    }
    h3 {
      text-align: center;
      font-size: 14px;
      color: #9a9a9a;
      font-weight: 400;
    }
  }
  .List_Container {
    width: 90%;
    padding: 1rem 1rem;
    background-color: transparent;
    position: relative;
    z-index: 12;
    margin: auto;
    border-radius: 4px;
    margin-top: -2rem;
    margin-bottom: 3rem;
    top: 18vh;
    .Count {
      position: absolute;
      background-color: $PRIMARY_COLOR;
      padding: 8px 12px;
      position: absolute;
      left: 8px;
      top: 0;
      transform: translateY(-50%);
      border-radius: 4px;
      p {
        color: white;
        font-weight: 500;
      }
    }
    h5 {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    .Project_Item {
      background-color: #f9f9f9;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
      border: none;
      border-radius: 4px;
      outline: none;
      h2 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .Status {
        display: flex;
        align-items: center;
        h3 {
          color: #9a9a9a;
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
          img {
            margin-right: 0.25rem;
          }
        }
      }
      .Status {
        display: flex;
      }
    }
  }
}
