@import "../../index.scss";
.SignIn_Container {
  padding: 1rem;
  height: 100%;
  .Carousel_Container {
    padding: 1rem;
    height: 65%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    
    img {
      width: 100%;
      aspect-ratio: 1/1;
    }
    h3 {
      color: $TEXT_COLOR;
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
    }
    h4 {
      color: $TEXT_COLOR;
      text-align: left;
      font-size: 0.75rem;
      font-weight: 500;
    }
    .Count_Controller {
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      margin-top: 1rem;
      .Inactive {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: gray;
        margin: 0 0.125rem;
      }
      .Active {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: $PRIMARY_COLOR;
        margin: 0 0.125rem;
      }
    }
  }
  .Form_Container {
    padding: 1rem;
    height: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    width: 90%;
    input {
      width: 100%;
      padding: 14px;
      background-color: #ebebeb;
      border: none;
      outline: none;
      border-radius: 4px;
      height: 3rem;
      margin-bottom: 1rem;

      &::placeholder {
        font-size: 14px;
      }
    }
    button {
      width: 100%;
      padding: 12px;
      background-color: $PRIMARY_COLOR;
      color: white;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      border-radius: 4px;
      margin-bottom: 1rem;
      //   height: 32px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: $TEXT_COLOR;
      text-align: center;
      margin-top: 0.5rem;
    }
    a {
      text-decoration: none;
      color: $PRIMARY_COLOR;
      font-weight: 700;
      font-size: 14px;
    }

    .logo {
      height: 30px;
      width: auto;
      opacity: 0.3;
      position: fixed;
      bottom: 20px;
    }
  }
}
